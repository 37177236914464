<template>
  <div class="evaluate-content">
    <van-nav-bar
      title="哇哆wowdoo(成都三利广场店)"
      left-arrow
      @click-left="onClickLeft"
      right-text="发布"
      @click-right="onClickRight"
    />
    <div
      class="write df aic jcsb fz20 p20 bsbb"
      style="color: #fc5f2c"
      @click="goWrite"
    >
      <div class="df aic">
        <van-icon name="records" class="mr20" />
        <p>评价100字+3张图,最高可获100抵现积分</p>
      </div>
    </div>
    <div class="start df aic jcsb p30 bsbb">
      <van-rate style="width: 690px" v-model="user_grade" />
    </div>
    <div class="text p30 bsbb">
      <textarea
        cols="30"
        rows="8"
        class="fz24"
        placeholder="亲,玩的开心吗?环境怎么样,服务满意吗?(写够15字,才是好同志~)"
      ></textarea>
      <p class="fz24 mb20">
        再评论15字,<span style="color: orange">有机会获得抵现积分</span>
      </p>
      <div class="pic df aic" style="width: 100%;">
        <van-uploader
          :after-read="afterRead"
          v-model="user_imgs"
          multiple
        />
      </div>
      <div class="df fdc mb20" style="padding-bottom: 10px">
        <div class="df aic jcsb fz28">
          <p>匿名评价</p>
          <van-switch v-model="checked" />
        </div>
      </div>
      <van-divider />
      <p class="fz20 mt40" style="color: #ddd">
        选择匿名后,商家或其他用户将无法获取您的个人信息,您将无法获得本评价的积分或奖励
      </p>
    </div>
  </div>
</template>

<script>
import { upload, getNewEvaluateData } from "@/utils/api.js";
export default {
  data() {
    return {
      user_imgs: [],
      checked: false,
      user_grade: 0, //评分等级
      user_title: "", //评价标题
      user_text: "", //评价内容
      id: "",
      user_video: "", //评价视频
      user_anonymous: 10, //10匿名 20否
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      getNewEvaluateData({ user_grade: this.user_grade, id: 1 }).then((res) => {
        console.log(res);
      });
    },
    afterRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },
    // isNotAnony() {
    //   if (this.checked === true) {
    //     this.user_anonymous = 10;
    //   } else {
    //     this.user_anonymous = 20;
    //   }
    // },
  },
  computed: {
    isNotAnony() {
      if (this.checked === true) {
        return (this.user_anonymous = 10);
      } else {
        return (this.user_anonymous = 20);
      }
    },
  },
  created() {
    console.log(this.user_anonymous);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-image {
  width: 200px;
  height: 200px;
}
::v-deep .van-rate__item {
  width: 120px;
}
::v-deep .van-rate__icon {
  font-size: 40px;
}
::v-deep .van-uploader__input {
  width: 200px;
  height: 200px;
}
::v-deep .van-uploader__upload {
  width: 200px;
  height: 200px;
}
::v-deep .van-uploader__upload-icon {
  font-size: 40px;
}
::v-deep .van-nav-bar__text {
  width: 80px;
  height: 40px;
  background: #fc5f2c;
  color: #fff;
  border-radius: 20px;
  line-height: 40px;
}
::v-deep .van-nav-bar {
  height: 60px;
  padding-top: 20px;
  font-size: 20px;
}
.evaluate-content {
  background: #fff;
  .write {
    width: 690px;
    height: 80px;
    background: #feefea;
    border-radius: 40px;
    margin: 40px auto;
  }
  .start {
    width: 690px;
    height: 100px;
  }
  .text {
    margin: 0 auto;
    textarea {
      border: none;
      width: 690px;
      height: 300px;
      border-radius: 20px;
    }
    .pic {
      padding-bottom: 40px;
    }
  }
}
</style>
<style lang="scss">
.van-nav-bar{
  line-height: normal !important;
}
.van-nav-bar__title{
  font-size: 28px !important;
}
.van-nav-bar__left{
  font-size: 28px !important;
}
</style>